.notfound-container{
    color: #c1c1c1;
    background-color: #0c0c0c;
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
}
