.App {
  font-family: "redhatRegular", sans-serif;
}

button {
  font-family: "redhatRegular", sans-serif;
}

.body-wrapper {
  min-height: 80vh;
  background-color: #0c0c0c;
  padding-bottom: 80px;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-family: "barlow";
  letter-spacing: 0.02em;
}

@font-face {
  font-family: "barlow";
  src: url("./assets/font/Barlow-Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "redhatBold";
  src: url("./assets/font/RedHatDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "redhatRegular";
  src: url("./assets/font/RedHatDisplay-Regular.ttf") format("truetype");
}

h2 {
  text-transform: uppercase;
  text-align: center;
  font-family: "barlow";
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
}

.main-btn {
  transition: 0.3s;
}

.main-btn:hover {
  color: #fff;
  transform: scale(1.05);
  box-shadow: 0 0 3px 0 rgba(50, 84, 252, 0.596);
}

.main-btn-alt {
  font-size: 18px;
  background-color: transparent;
  border: 1px solid currentColor;
  border-radius: 10px;
    padding: 15px 45px;
  color: currentColor;
  font-family: "redhatRegular";
  cursor: pointer;
  transition: 0.3s;
  
}



.main-btn-alt:hover{
  scale: 1.1;
}

.main-btn {
  font-size: 18px;
  background: linear-gradient(
    31.11deg,
    rgb(50, 84, 252) -1.31%,
    rgb(131, 75, 239) 129.36%
  );
  border-radius: 10px;
  padding: 15px 45px;
  color: #f7f7f7;
  font-family: "redhatRegular";
  cursor: pointer;
}

.whats-btn a {
  z-index: 0;
  opacity: 1;
  z-index: 50;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  padding: 10px;
  border-radius: 99px;
  transition: 0.4s;
  font-size: 60px;
  color: #3254fc;
  background-color: #0c0c0c;
}

.whats-btn a:hover {
  background-color: #3254fc;
  color: #0c0c0c;
}




@media (max-width: 450px) {
  p{
    font-size: 16px !important;
  }

  .home-container{
    padding-top: 60px;
  }

  .home-btn-wrapper{
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  .home-btn-wrapper a{
    width: 100%;
    font-size: 16px !important;
  }

  .half-image{
    padding: 20px !important;
    margin-top: 20px !important;
  }

  h1{
    font-size: 24px !important;
  }

  .portfolio-header{
    padding-top: 60px  !important;
  }

}